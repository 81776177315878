const protocol = window.location.protocol;

const server = `${protocol}//bodegascallia.com/api`;

async function fetchCalliaServer(url, idioma) {
  const res = await fetch(server + url, {
    headers: { Etiquetaidioma: idioma },
  });

  return res;
}

export async function getTipoVinos(idioma) {
  const res = await fetchCalliaServer("/vinos/get-tipos-vinos", idioma);
  const json = await res.json();
  return json;
}

export async function getListadoVinos(tipoVino, idioma) {
  const res = await fetchCalliaServer(`/vinos/${tipoVino}`, idioma);
  const json = await res.json();
  return json;
}

export async function getDetalleVino(id, idioma) {
  const res = await fetchCalliaServer(`/vinos/get-vino/${id}`, idioma);
  const json = await res.json();
  return json;
}

export async function getTours(idioma) {
  const res = await fetchCalliaServer("/tours", idioma);
  const json = await res.json();
  return json;
}

export async function getEquipo(idioma) {
  const res = await fetchCalliaServer("/team", idioma);
  const json = await res.json();
  return json;
}

//Medio raro
export async function getBullets(tipoBullet, idioma) {
  const res = await fetchCalliaServer(
    `/bullets?tipoBullet=${tipoBullet}`,
    idioma
  );
  const json = await res.json();
  return json;
}

export async function postContacto(formData) {
  const res = await fetch(server + "/contactos", {
    method: "POST",
    headers: { Etiquetaidioma: 0 },
    body: JSON.stringify(formData),
  });
  const json = await res.json();
  return json;
}

export async function postModalReserva(formData) {
  const res = await fetch(server + "/contactos/crear-reserva", {
    method: "POST",
    headers: { Etiquetaidioma: 0, "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  });

  const json = await res.json();
  return json;
}
