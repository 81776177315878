import { createIcon } from "@chakra-ui/react";

export const SustentabilidadIcon = createIcon({
  displayName: "SustentabilidadIcon",
  viewBox: "0 0 23 23",
  path: (
    <path
      d="M2.875 4.27207H0C0 9.8334 4.50566 14.3346 10.0625 14.3346V20.0846C10.0625 20.9156 10.7094 21.5625 11.4596 21.5625C12.2098 21.5625 12.9375 20.9156 12.9375 20.125V14.375C12.9375 8.81816 8.43184 4.27207 2.875 4.27207ZM20.125 1.4375C16.3403 1.4375 13.0543 3.52637 11.3293 6.61699C12.5759 7.97004 13.4968 9.62137 13.9797 11.4461C19.0469 10.9205 23 6.64395 23 1.4375H20.125Z"
      fill="#931414"
    />
  ),
});
