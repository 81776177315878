import { useEffect, useState, useContext } from "react";
import CuadroImagen from "../components/CuadroImagen";
import HeaderBannerSmall from "../components/HeaderBannerSmall";
import ImagenCuadro from "../components/ImagenCuadro";
import { getTours } from "../utils/APICalls";
import { LanguajeContext } from "../App";
const apiImages = "api/";

const Tours = () => {
  const [listadoTours, setListadoTours] = useState();

  const { lang } = useContext(LanguajeContext);

  useEffect(() => {
    getTours(lang).then((json) => setListadoTours(json.extraInfo));
  }, [lang]);
  return (
    <>
      <HeaderBannerSmall
        title={lang ? "Tourism" : "Turismo"}
        image={"./img/tours-header.png"}
      />
      {listadoTours &&
        listadoTours.map((unTour, i) => {
          if (i % 2 === 0) {
            return (
              <ImagenCuadro
                variant={"tour"}
                duracion={unTour.duracion}
                texto={unTour.texto}
                title1={unTour.titulo}
                horarios={
                  unTour.diasDisponibles ? unTour.diasDisponibles : null
                }
                key={unTour.id}
                imagen={apiImages + unTour.pathFoto}
                reserva={unTour.aclaracion}
                modalType={unTour.titulo}
              />
            );
          }
          return (
            <CuadroImagen
              variant={"tour"}
              duracion={unTour.duracion}
              texto={unTour.texto}
              title1={unTour.titulo}
              horarios={
                unTour.diasDisponibles
                  ? unTour.diasDisponibles.split(",")
                  : null
              }
              key={unTour.id}
              imagen={apiImages + unTour.pathFoto}
              reserva={unTour.aclaracion}
              modalType={unTour.titulo}
            />
          );
        })}
    </>
  );
};

export default Tours;
