import HeaderBanner from "../components/HeaderBanner";
import CuadroImagen from "../components/CuadroImagen";
import ImagenCuadro from "../components/ImagenCuadro";
import { motion } from "framer-motion";
import { opacityRouteVariants } from "../utils/animations";
import { useContext } from "react";
import { LanguajeContext } from "../App";

const Home = () => {
  const { lang } = useContext(LanguajeContext);
  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <HeaderBanner
        bgImg={"./img/header-banner.png"}
        claim={lang ? "Back to yourself" : "Volver a vos"}
      />
      <ImagenCuadro
        headerNumber={"1"}
        headerText={lang ? "THE WINERY" : "LA BODEGA"}
        title1={lang ? "Modern" : "Moderna"}
        title2={lang ? "and innovative" : "e innovadora"}
        imagen={"./img/moderna-innovadora.png"}
        texto={
          lang
            ? "Bodegas Callia was born in a magical place in the Caucete district, in the Tulum Valley of San Juan province, where the vines are exposed to year-round sun, gentle, dry wind, and abundant, fresh mountain water. All of these factors provide the ideal climatic conditions to produce uniquely styled, high-quality, fruity, rounded wines."
            : "Bodegas Callia nace en un lugar mágico en el Valle de Tulum, departamento de Caucete, provincia de San Juan. Moderna e innovadora, la actual bodega fue construida bajo altos estándares de calidad teniendo en cuenta el patrimonio histórico y paisajístico de la zona."
        }
        href={"/bodega"}
      />
      <CuadroImagen
        headerNumber={"2"}
        headerText={lang ? "Our wines" : "Nuestros vinos"}
        title1={lang ? "Identity " : "Identidad "}
        title2={lang ? "in the wine" : "en el vino"}
        texto={
          lang
            ? "Sourced from various San Juan valleys, a province dominated by mountain ranges that enclose the valleys – each with different characteristics and elevations – the grapes nonetheless share common denominators that allow for their near-natural cultivation."
            : "Las uvas de diferentes Valles de San Juan, provincia dominada por cadenas montañosas que encierran valles con distintas características y altitudes, poseen denominadores comunes que permiten una producción agrícola prácticamente natural."
        }
        href={"/vinos"}
        imagen={"./img/uva.webp"}
      />
      <ImagenCuadro
        headerNumber={"3"}
        headerText={lang ? "TOURISM" : "TURISMO"}
        title1={lang ? "Enjoy the" : "Viví la"}
        imagen={"./img/img-home.webp"}
        title2={lang ? "experience" : "experiencia"}
        texto={
          lang
            ? "We invite you to discover our winery and estates, and enjoy the experience of touring the vineyards and learning more about their diverse soils and the characteristics that give our wines their special, unique personality."
            : "Te invitamos a descubrir los espacios de nuestra bodega y fincas para vivir la experiencia de recorrer los viñedos, sus diferentes suelos y características que le otorgan una personalidad especial y única a nuestros vinos."
        }
        href={"/tours"}
      />
    </motion.div>
  );
};

export default Home;
