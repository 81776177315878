import { HStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {  titleCase } from "../utils/functions";
import { useContext } from "react";
import { LanguajeContext } from "../App";
const tiposVinos = [
  "",
  "",
  "Callia Core",
  "Tardio",
  "Contracara",
  "Esperado",
  "Espumantes",
  "Selected",
];

const tiposVinosEN = [
  "",
  "",
  "Callia Core",
  "Tardio",
  "Contracara",
  "Esperado",
  "Sparkling Wines",
  "Selected",
]

const Breadcrumbs = ({ tipoVino, vino }) => {
  const { lang } = useContext(LanguajeContext);
  
  const stateRedirect = parseInt(tipoVino) - 2;

  return (
    <HStack
      fontFamily={"Montserrat"}
      fontStyle={"italic"}
      color={"#931414"}
      fontSize={"12px"}
    >
      <Link to="/vinos">
        <Text
          borderBottom={"1px solid transparent"}
          _hover={{ borderBottom: "1px solid #931414" }}
        >
          {lang ? "Wines" : "Vinos"}
        </Text>
      </Link>
      <Text>/</Text>
      <Link to={"/vinos"} state={{ activeVino: stateRedirect.toString() }}>
        <Text
          borderBottom={"1px solid transparent"}
          _hover={{ borderBottom: "1px solid #931414" }}
        >
          {lang ? titleCase(tiposVinosEN[tipoVino]) : titleCase(tiposVinos[tipoVino])}
        </Text>
      </Link>
      <Text>/</Text>
      <Text borderBottom={"1px solid transparent"} fontWeight={"600"}>
        {titleCase(vino)}
      </Text>
    </HStack>
  );
};

export default Breadcrumbs;
