import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { SustentabilidadIcon } from "./icons/SustentabilidadIcon";

const SustentabilidadSegundo = ({ title, subtitle, listItems }) => {
  return (
    <Grid
      templateColumns={{ lg: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}
      pt={24}
    >
      <GridItem>
        <Box
          py={{ lg: 5, xs: 12 }}
          pl={{ lg: 12 }}
          display={{ lg: "unset", md: "flex" }}
          justifyContent={"center"}
        >
          <Box>
            <Image src="./img/sustentabilidad-2.png" pb={4} />
            <Image src="./img/sustentabilidad-3.png" />
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box px={{ lg: 4, md: 20, xs: 8 }}>
          <Text
            fontFamily={"Playfair Display"}
            fontWeight={"600"}
            fontSize={"26px"}
            lineHeight={"37px"}
            color={"calliaGray"}
          >
            {title}
          </Text>
          <Text
            fontFamily={"Playfair Display"}
            fontWeight={"700"}
            fontStyle={"italic"}
            fontSize={"32px"}
            lineHeight={"37px"}
            color={"rojo"}
            pb={5}
          >
            {subtitle}
          </Text>
          <VStack
            fontSize={"13px"}
            fontFamily={"Montserrat"}
            lineHeight={"21px"}
            color={"calliaGray"}
            alignItems={"flex-start"}
            spacing={5}
          >
            {listItems.map((unItem) => (
              <HStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                <Icon as={SustentabilidadIcon} mt={1} />
                <Text>
                  <span style={{ fontWeight: "700" }}>{unItem.title}</span>{" "}
                  {unItem.text}
                </Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default SustentabilidadSegundo;
