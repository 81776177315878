export const generarTextosValles = (lang) => {
  const textosVallesExtra = lang
    ? {
        tulum: [
          {
            title: "Finca Pie de Palo estate",
            text: " located in Caucete, has an area of 180 hectares, mainly planted with Malbec and Syrah, but also with Chardonnay and Viognier. Some of these vineyards were planted between 1995 and 1998, while the younger vineyards were planted from 2012 to 2019",
          },
          {
            title: "Finca 9 de Julio estate",
            text: "located in the district of the same name, has 111 hectares, mostly planted with Malbec and Syrah, but also other red varieties such as Petit Verdot and Ancellota. Among the white varieties, there are Chardonnay, Viognier, Torrontés Riojano and Pinot Gris. Part of these vineyards were planted in 2000, and the rest were planted from 2016 to date.",
          },
        ],
        pedernal: [
          "As a consequence of the geological processes that formed the valley, the soils are rocky with stones of diverse compositions and size within a matrix composed of finer materials of varied composition but mainly of sand, silt and clay. Additionally, the deep soils have very good porosity, water infiltration and aeration.",
          "The primary red varieties grown here are Malbec, Cabernet Sauvignon, Cabernet Franc, Syrah, Merlot, Pinot Noir and, among the whites, Chardonnay and Sauvignon Blanc. What sets apart the wines from this area is the intense colour of the reds and their fruitiness with spice notes, while the whites are distinctive for their fruit, acidity and minerality.",
          "The soil in the valley’s southeast is 100% geologically calcareous, the sole place with this viticultural characteristic in Argentina, which, in addition to the elevation and the climate, sets Pedernal apart from any other terroir. All this means that the valley has exceptional conditions for the production of very high quality grapes, making it an officially recognised Argentine wine Geographical Indication (IG) since 2007.",
        ],
      }
    : {
        tulum: [
          {
            title: "Finca Pie de Palo",
            text: `ubicada en Caucete, cuenta con una superficie de 180
          hectáreas, principalmente de Malbec y Syrah, pero también
          hay plantaciones de Chardonnay y Viognier. Algunos de esos
          viñedos fueron plantados entre 1995 y 1998, mientras que
          otros, más jóvenes, son de los años 2012 a 2019.`,
          },
          {
            title: "Finca 9 de Julio:",
            text: `ubicada en el departamento del mismo nombre, cuenta con
            111 hectáreas. Aquí se encuentran principalmente uvas de
            las cepas Malbec y Syrah, pero también hay otras
            variedades tintas como Petit Verdot y Ancellota. Entre las
            variedades blancas, tenemos Chardonnay, Viognier,
            Torrontés Riojano y Pinot Gris. Parte de los viñedos son
            del año 2000 y el resto fue plantado desde 2016 a la
            fecha.`,
          },
        ],
        pedernal: [
          `Como consecuencia de los procesos de formación geológica
        del valle, los suelos son pedregosos con rocas de distinta
        composición y tamaño dentro de una matriz constituida por
        materiales finos de variada composición, principalmente
        arenas, limo y arcillas. Del mismo modo, los perfiles
        profundos tienen una muy buena porosidad, infiltración y
        aireación.`,
          `Las principales variedades tintas cultivadas son Malbec,
          Cabernet Sauvignon, Cabernet Franc, Syrah, Merlot, Pinot
          Noir y, entre las blancas, Chardonnay y Sauvignon Blanc.
          La particularidad de los vinos de esta zona es que los
          tintos presentan colores intensos, son frutados y con
          notas especiadas, mientras que los blancos se destacan por
          la fruta, la acidez y la mineralidad.`,
          `Al sudeste del valle el suelo es 100% calcáreo geológico,
          único con estas características en la vitivinicultura
          argentina lo que –sumado a la altura y al clima– hacen a
          Pedernal diferente a cualquier otro terruño. Todo esto
          genera que el valle posea condiciones excepcionales para
          la producción de uvas de muy alta calidad y que, desde
          2007, sea una Identificación Geográfica (IG) vitivinícola
          Argentina.`,
        ],
      };

  const textosValles = lang
    ? {
        tulum: [
          "The Tulum valley, most important in the San Juan province, is situated 630 metres above sea level. Here you’ll find more than cultivated 45,000 hectares of wine grapes. The soils are alluvial in origin, heterogeneous with regard to their profile characteristics, and with textures ranging from clay, sandy to loamy. Although this diversity results in more complex management, it gives us the opportunity of having different environments – terroirs – to produce different wines.",
          "The valley’s climate is arid, with an annual average temperature of 17.5°C. January is the warmest month, with an average of 25.7°C, and July the coolest, with average temperatures of 8.6°C. Total annual precipitation is approximately 90 mm, which is concentrated in spring and summer, and the average relative humidity is 53 percent. ",
          "The combination of the sun’s warmth, low precipitation, low relative humidity, irrigation using Andean meltwater, and the ideal soils for cultivating vines create a perfect environment for the production of the highest oenological-quality wines.",
          "The predominant grape varieties destined for winemaking in the valley are, among the reds, Malbec, Syrah, Bonarda and Cabernet Sauvignon. While the whites are led by Torrontés Riojano, Pinot Gris, Chardonnay and Viognier.",
          "Despite being a young winery, Callia occupies an important place in the production of wines in the Tulum Valley. The estates we have here have special microclimates for the production of high oenological-quality grapes that are naturally very healthy.",
        ],
        pedernal: [
          "The Pedernal Valley is the rising star of San Juan grapes due to its elevations and location, at the foot of the Andes mountain range with elevations ranging from 1,110 to 1,500 metres above sea level. It is a closed valley, far from large urban areas and isolated from natural pests, which provides unique conditions for the cultivation of the highest oenological-quality vines. There are currently approximately 800 hectares of vineyards planted, and the stony alluvial soils, which are gravity irrigated, lend the grapes intense colour and fruity flavours.",
          "The Pedernal Valley, which enjoys a wide thermal amplitude (20°C), is also notable for its clear sky, its continental, arid, desert climate, and, generally, year-round sun. All these conditions result in the optimal growth of the varieties planted there. Additionally, due to the valley’s topographic characteristics, such as varying slope orientation, elevations and gradients, the valley has diverse microclimate zones.",
        ],
        zonda: [
          "The valley is located northwest of the Tulum Valley, and despite being much smaller – it has only about 1,200 hectares of vineyards planted – it has great significance due to its quality of grapes for winemaking.",
          "The valley is situated at around 800 metres above sea level and, though daytime temperatures can be somewhat lower than in the Tulum valley, the greatest difference are the nighttime temperatures, which increases the thermal amplitude differential. Additionally, the general topography of the valley also influences the temperatures.",
          "The soils here are alluvial, with a greater percentage of finer materials at the bottom of the valley and a predominance of variously-sized gravel in the foothills and at the banks of the San Juan River. As a consequence of the combination of various topographies, elevations and soils, diverse terroirs have been created that are expressed in the grapes and wines that further distinguish this valley.",
          "The highest oenological-quality varietals grown here are Malbec, Syrah, Cabernet Sauvignon, Cabernet Franc, Pinot Noir and Tannat. And among the whites, Chardonnay, Sauvignon Blanc and Viognier. Pinot Gris is also cultivated and used to make white wine.",
        ],
        calingasta: [
          "The valley is located to the west, between the Andes Mountains and the foothills, and is aligned in a north-south direction, encompassing several zones of diverse climate and soil characteristics. This diversity results in various environments or terroirs, which are expressed in its grapes and wines.",
          "The elevations of the terroirs mainly range between 1,400 and 1,700 metres above sea level. Importantly, daytime temperatures here are similar to those of the Tulum valley, but nighttime temperatures are significantly lower. This creates very favourable conditions for the production of grapes of the highest oenological-quality and, importantly, their natural health.",
        ],
      }
    : {
        tulum: [
          `El valle de Tulum es el más importante de la provincia de
        San Juan y se encuentra a 630 metros sobre el nivel del
        mar. Aquí pueden encontrarse más de 45.000 hectáreas
        cultivadas con uvas de vinificar. Los suelos son de origen
        aluvional, heterogéneos en cuanto a las características de
        sus perfiles, con texturas que van desde los arcillosos,
        arenosos hasta los francos. Si bien esta diversidad
        implica un manejo más complicado, nos brinda la
        posibilidad contar con ambientes distintos –terruños– para
        elaborar vinos también diferentes.`,
          `El clima es árido, con una temperatura media de 17,5°C.
          Enero es el mes más cálido, con una media de 25,7°C y
          julio, el más frío con 8,6°C. Las precipitaciones anuales
          son de aproximadamente 90 mm, concentradas en
          primavera-verano, y la humedad relativa media es del 53
          por ciento.`,
          `La calidez del sol, las escasas precipitaciones, la baja
          humedad relativa ambiente, el riego con agua de deshielo
          de los Andes y los suelos ideales para el cultivo de vides
          crean un ambiente perfecto para la elaboración de vinos de
          alta calidad enológica.`,
          `Las variedades predominantes de uvas destinadas a la
          vinificación en este valle son, entre las tintas, de las
          cepas Malbec, Syrah, Bonarda y Cabernet Sauvignon.
          Mientras que entre las blancas, se pueden encontrar
          Torrontés Riojano, Pinot Gris, Chardonnay y Viognier.`,
          ` A pesar de ser una bodega joven, Callia ocupa un lugar
          importante en la producción y elaboración de vinos dentro
          del Valle de Tulum. Las fincas aquí ubicadas cuentan con
          un microclima especial para la producción de uvas de alta
          calidad enológica y con una buena sanidad natural.`,
        ],
        pedernal: [
          `El Valle de Pedernal es la nueva promesa de las uvas de
        San Juan por su altura y ubicación: se encuentra al pie de
        la cordillera de los Andes y su altitud oscila entre los
        1.110 y 1.500 metros sobre el nivel del mar. Se trata de
        un valle confinado, lejos de grandes centros urbanos y
        aislado de plagas naturales, lo que le brinda condiciones
        únicas para el cultivo de cepajes de alta calidad
        enológica. Actualmente, hay aproximadamente 800 hectáreas
        de viñedos y sus suelos pedregosos y aluvionales, en los
        que el riego es por vertiente, otorgan a las uvas colores
        intensos y sabores frutados.`,
          `Asimismo, Pedernal es un valle caracterizado por la gran
          amplitud térmica (20°C) se destaca por lo diáfano de su
          cielo, con un clima continental, árido, desértico y, por
          lo general, soleado durante todo el año. Todo esto permite
          el óptimo desarrollo de las variedades que allí se
          plantan. Por otra parte, debido a las características de
          su topografía, con laderas de diversas orientaciones,
          altitudes y pendientes, existen dentro del valle áreas con
          diferentes condiciones microclimáticas.`,
        ],
        zonda: [
          `Se encuentra al noroeste del Valle de Tulum y, a pesar de
        ser mucho más pequeño –cuenta con, aproximadamente, unas
        1.200 hectáreas con viñedos–, tiene una gran significación
        debido a su calidad en la elaboración de vinos.`,
          `El valle se ubica en torno a los 800 metros sobre el nivel
          del mar y, si bien las temperaturas diurnas pueden ser
          algo menores que en el valle de Tulum, la mayor diferencia
          se registra en las nocturnas, lo que incrementa la
          amplitud térmica diaria. Por otra parte, la topografía
          general también influye en los registros térmicos.`,
          `Los suelos son aluviales, con mayor porcentaje de
          materiales finos en el fondo del valle y predominio de
          ripios de diverso tamaño en el piedemonte y en el margen
          del río San Juan. Como consecuencia de la combinación de
          diferentes topografías, altitudes y suelos, se han
          generado diferentes terruños que se expresan en las uvas y
          vinos que distinguen más aún a este valle.`,
          `Respecto a las variedades, se cultivan en general las de
          alta calidad enológica, como Malbec, Syrah, Cabernet
          Sauvignon, Cabernet Franc, Pinot Noir y Tannat. Entre las
          blancas, Chardonnay, Sauvignon Blanc y Viognier. También
          el Pinot Gris, que se elabora como blanca.`,
        ],
        calingasta: [
          `Se encuentra al oeste, entre la precordillera y la
        cordillera de los Andes, y es un valle ubicado en
        dirección norte-sur, que abarca varias localidades con
        diferentes características climáticas y de suelo. Esto
        genera ambientes o terruños diversos, que se expresan en
        sus uvas y vinos.`,
          `La altitud a la que se encuentran los terruños oscila
          principalmente entre los 1.400 y 1.700 metros sobre el
          nivel del mar. Una característica saliente es que las
          temperaturas diurnas son similares a las del valle de
          Tulum, pero las nocturnas son significativamente menores.
          Esto crea condiciones muy favorables para la producción de
          uvas de alta calidad enológica y una sanidad natural
          importante.`,
        ],
      };

  return { textosValles, textosVallesExtra };
};
