import HeaderBannerSmall from "../components/HeaderBannerSmall";
import ImagenCuadro from "../components/ImagenCuadro";
import CuadroImagen from "../components/CuadroImagen";
import { motion } from "framer-motion";
import { opacityRouteVariants } from "../utils/animations";
import { useContext } from "react";
import { LanguajeContext } from "../App";
const Bodega = () => {
  const { lang } = useContext(LanguajeContext);
  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <HeaderBannerSmall
        title={lang ? "The Winery" : "La Bodega"}
        image={"./img/bodega-header.jpg"}
      />
      <ImagenCuadro
        variant="noButton"
        imagen="./img/bodega-img-1.webp"
        title1={lang ? "Located" : "Ubicada"}
        title2={lang ? "in the Tulum Valley" : "en el Valle Tulum."}
        texto={
          lang
            ? "Bodegas Callia was born in a magical place in the Caucete districts Tulum valley in San Juan province, where the vines are exposed to year-round sun, gentle, dry wind and abundant, fresh mountain water, all of which present the ideal climatic conditions to produce uniquely styled high-quality, fruity, rounded wines."
            : "Bodegas Callia nace en un lugar mágico en el Valle de Tulum, departamento de Caucete, provincia de San Juan. Las vides reciben aquí sol permanente, viento suave y seco y abundante agua que baja de las montañas, logrando las condiciones climáticas ideales para producir vinos de alta calidad, frutados, redondos y con un estilo único."
        }
      />
      <CuadroImagen
        variant="noButton"
        title1={lang ? "Modern" : "Moderna"}
        title2={lang ? "and innovative..." : " e innovadora..."}
        imagen={"./img/bodega-img-2.webp"}
        texto={
          lang
            ? "The current winery was built under high quality standards that took into consideration the areas historical heritage and landscape. Every detail such as respect for biodiversity and native vegetation was thoroughly thought out and the construction utilised the most advanced anti-seismic techniques in the world."
            : "La actual bodega fue construida bajo altos estándares de calidad teniendo en cuenta el patrimonio histórico y paisajístico de la zona. Cada detalle, como el respeto de la biodiversidad y vegetación autóctona, fue cuidadosamente calculado y la construcción fue realizada bajo técnicas anti-sísmicas de las más avanzadas del mundo."
        }
      />
      <ImagenCuadro
        variant="salentein"
        imagen="./img/bodega-img-3.webp"
        titleImage=""
        texto={
          lang
            ? "Bodegas Callia is part of the Salentein Family of Wines, a Dutch company that has two other wineries: Pyros Wines, in San Juan, and Bodegas Salentein, in Mendoza’s Uco Valley. Its wines are innovative, a trendsetter in Argentine oenology, and its wines have been successfully exported to more than 40 countries around the world."
            : "Bodegas Callia pertenece a Salentein Family of Wines, de capitales holandeses, que cuenta también con otras dos bodegas: Pyros Wines, en San Juan, y Bodegas Salentein, en el Valle de Uco, Mendoza. Sus vinos son una propuesta innovadora, que marca tendencia en la enología argentina y se exportan con éxito a más de 40 países del mundo. "
        }
      />
    </motion.div>
  );
};
export default Bodega;
