import { Grid, GridItem, Text, Image, Box } from "@chakra-ui/react";

const SustentabilidadPrimero = ({
  title,
  subtitle,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  salentein,
}) => {
  return (
    <Grid
      templateColumns={{ lg: "repeat(2, 1fr)", xs: "repeat(1, 1fr)" }}
      pt={20}
    >
      <GridItem order={{ lg: 1, xs: 2 }}>
        <Box px={{ lg: 0, md: 20, xs: 8 }}>
          <Text
            fontFamily={"Playfair Display"}
            fontWeight={"600"}
            fontSize={"26px"}
            color={"calliaGray"}
          >
            {title}
          </Text>
          <Text
            fontFamily={"Playfair Display"}
            fontWeight={"700"}
            fontStyle={"italic"}
            fontSize={"26px"}
            color={"rojo"}
            pb={3}
          >
            <span
              style={{
                fontFamily: "Trajan Pro",
                color: "#383A37",
                letterSpacing: "0.05em",
                fontWeight: "400",
                fontStyle: "normal",
                textTransform: "uppercase",
              }}
            >
              <span style={{ fontSize: "30px" }}>S</span>alentein{" "}
              <span style={{ fontSize: "30px" }}>F</span>amily of{" "}
              <span style={{ fontSize: "30px" }}>W</span>ines
              <br />
            </span>
            {subtitle}
          </Text>
          <Text
            fontFamily={"Montserrat"}
            fontSize={"13px"}
            lineHeight={"21px"}
            color={"calliaGray"}
          >
            <Text pb={3}>{firstParagraph}</Text>
            <Text pb={3}>{secondParagraph}</Text>
            <Text>{thirdParagraph}</Text>
          </Text>
        </Box>
      </GridItem>
      <GridItem order={{ lg: 2, xs: 1 }}>
        <Box
          py={{ lg: 5, xs: 12 }}
          pl={{ lg: 12 }}
          display={{ lg: "block", md: "flex" }}
          justifyContent={"center"}
        >
          <Image src="./img/sustentabilidad-1.png" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default SustentabilidadPrimero;
