import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { useState, useContext } from "react";
import {
  ChakraBox,
  comeFromRightVariants,
  opacityStaggerVariants,
} from "../utils/animations";
import { postContacto } from "../utils/APICalls";
import Swal from "sweetalert2";
import { LanguajeContext } from "../App";

const ContactoForm = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    asunto: "",
    mensaje: "",
  });

  const { lang } = useContext(LanguajeContext);

  function handleSubmit() {
    postContacto(formData).then((json) => {
      if (json.status === "OK") {
        Swal.fire({
          title: lang ? "Success!" : "Exito!",
          text: lang
            ? "The message has been sent successfully."
            : "Se ha enviado el mensaje correctamente.",
          icon: "success",
          confirmButtonText: lang ? "Continue" : "Continuar",
          confirmButtonColor: "rgba(147, 20, 20, 1)",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: lang
            ? "We had a problem sending the desired message"
            : "Tuvimos un problema para enviar el mensaje deseado.",
          icon: "error",
          confirmButtonText: lang ? "Retry" : "Reintentar",
          confirmButtonColor: "rgba(147, 20, 20, 1)",
        });
      }
    });
  }

  return (
    <FormControl>
      <ChakraBox
        variants={opacityStaggerVariants}
        initial="hidden"
        animate="show"
      >
        <ChakraBox pt={4} variants={comeFromRightVariants}>
          <FormLabel
            fontSize={"13px"}
            color={"calliaGray"}
            fontWeight={"600"}
            fontFamily={"'Montserrat', sans-serif"}
            letterSpacing={"0.06em"}
          >
            {lang ? "Name" : "Nombre"}
          </FormLabel>
          <Input
            type="text"
            borderRadius={"0"}
            borderColor={"#9E9E9E"}
            borderWidth={"0.8px"}
            value={formData.nombre}
            onChange={(event) =>
              setFormData({ ...formData, nombre: event.target.value })
            }
          />
        </ChakraBox>
        <ChakraBox pt={4} variants={comeFromRightVariants}>
          <FormLabel
            fontSize={"13px"}
            color={"calliaGray"}
            fontWeight={"600"}
            fontFamily={"'Montserrat', sans-serif"}
            letterSpacing={"0.06em"}
          >
            {lang ? "Email" : "Correo electrónico"}
          </FormLabel>
          <Input
            type="email"
            borderRadius={"0"}
            borderColor={"#9E9E9E"}
            borderWidth={"0.8px"}
            value={formData.email}
            onChange={(event) =>
              setFormData({ ...formData, email: event.target.value })
            }
          />
        </ChakraBox>
        <ChakraBox pt={4} variants={comeFromRightVariants}>
          <FormLabel
            fontSize={"13px"}
            color={"calliaGray"}
            fontWeight={"600"}
            fontFamily={"'Montserrat', sans-serif"}
            letterSpacing={"0.06em"}
          >
            {lang ? "Matter" : "Asunto"}
          </FormLabel>
          <Input
            type="text"
            borderRadius={"0"}
            borderColor={"#9E9E9E"}
            borderWidth={"0.8px"}
            value={formData.asunto}
            onChange={(event) =>
              setFormData({ ...formData, asunto: event.target.value })
            }
          />
        </ChakraBox>
        <ChakraBox pt={4} variants={comeFromRightVariants}>
          <FormLabel
            fontSize={"13px"}
            color={"calliaGray"}
            fontWeight={"600"}
            fontFamily={"'Montserrat', sans-serif"}
            letterSpacing={"0.06em"}
          >
            {lang ? "Message" : "Mensaje"}
          </FormLabel>
          <Textarea
            borderRadius={"0"}
            borderColor={"#9E9E9E"}
            borderWidth={"0.8px"}
            value={formData.mensaje}
            onChange={(event) =>
              setFormData({ ...formData, mensaje: event.target.value })
            }
          />
        </ChakraBox>
        <ChakraBox variants={comeFromRightVariants}>
          <Button
            borderRadius={"0"}
            colorScheme={"black"}
            textTransform={"uppercase"}
            letterSpacing={"0.03em"}
            fontFamily={"'Montserrat', sans-serif"}
            fontWeight={"500"}
            mt={8}
            px={8}
            onClick={handleSubmit}
          >
            {lang ? "Send" : "Enviar"}
          </Button>
        </ChakraBox>
      </ChakraBox>
    </FormControl>
  );
};

export default ContactoForm;
