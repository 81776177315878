import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
  Box,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useState, useContext } from "react";
import { postModalReserva } from "../utils/APICalls";
import { LanguajeContext } from "../App";
const TourModal = ({ isOpen, onClose, type }) => {
  const { lang } = useContext(LanguajeContext);
  const [gracias, setGracias] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: "",
    tour: "",
    telefono: "",
    mensaje: "",
  });

  const handleSubmit = () => {
    postModalReserva(formData).then((json) => {
      if (json.status === "OK") {
        setGracias(true);
      } else {
        alert("error enviando la informacion");
      }
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={"30px"} maxW="lg" mx={{ md: 0, xs: 4 }}>
          <ModalHeader
            display={gracias ? "none" : "flex"}
            justifyContent="center"
            px={{ md: 24, xs: 12 }}
            pt={10}
            pb={4}
          >
            <Text
              textAlign={"center"}
              fontSize={{ md: "27px", xs: "22px" }}
              lineHeight={"33px"}
              letterSpacing={"-0.02em"}
              fontWeight={"bold"}
            >
              {lang
                ? "Complete the form and make your reservation"
                : "Completá el formulario y hacé tu reserva"}
            </Text>
          </ModalHeader>
          <ModalBody px={{ md: 16, xs: 8 }}>
            <FormControl display={gracias ? "none" : "block"}>
              <FormLabel
                mt={2}
                fontSize={"15px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                {lang ? "Name:" : "Nombre:"}
              </FormLabel>
              <Input
                type="text"
                borderRadius={"0"}
                borderColor={"#A5A5A5"}
                borderWidth={"0.8px"}
                height={{ md: "40px", xs: "32px" }}
                value={formData.nombre}
                onChange={(event) =>
                  setFormData({ ...formData, nombre: event.target.value })
                }
              />
              <FormLabel
                mt={2}
                fontSize={"15px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                {lang ? "Surname:" : "Apellido:"}
              </FormLabel>
              <Input
                type="text"
                borderRadius={"0"}
                borderColor={"#A5A5A5"}
                borderWidth={"0.8px"}
                height={{ md: "40px", xs: "32px" }}
                value={formData.apellido}
                onChange={(event) =>
                  setFormData({ ...formData, apellido: event.target.value })
                }
              />
              <FormLabel
                mt={2}
                fontSize={"15px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                Mail:
              </FormLabel>
              <Input
                type="text"
                borderRadius={"0"}
                borderColor={"#A5A5A5"}
                borderWidth={"0.8px"}
                height={{ md: "40px", xs: "32px" }}
                value={formData.email}
                onChange={(event) =>
                  setFormData({ ...formData, email: event.target.value })
                }
              />
              <FormLabel
                mt={2}
                fontSize={"15px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                {lang ? "Phone number:" : "Teléfono:"}
              </FormLabel>
              <Input
                type="text"
                borderRadius={"0"}
                borderColor={"#A5A5A5"}
                borderWidth={"0.8px"}
                height={{ md: "40px", xs: "32px" }}
                value={formData.telefono}
                onChange={(event) =>
                  setFormData({ ...formData, telefono: event.target.value })
                }
              />
              <FormLabel
                mt={2}
                fontSize={"15px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                {lang ? "Matter:" : "Asunto:"}
              </FormLabel>
              <Input
                type="text"
                borderRadius={"0"}
                borderColor={"#A5A5A5"}
                borderWidth={"0.8px"}
                disabled
                placeholder={lang ? "Booking - " + type : "Reserva - " + type}
                height={{ md: "40px", xs: "32px" }}
                value={formData.tour}
                onChange={(event) =>
                  setFormData({ ...formData, tour: event.target.value })
                }
              />
              <FormLabel
                mt={2}
                fontSize={"15px"}
                lineHeight={"22px"}
                fontWeight={"600"}
              >
                {lang ? "Message:" : "Mensaje:"}
              </FormLabel>
              <Textarea
                borderRadius={"0"}
                borderColor={"#A5A5A5"}
                borderWidth={"0.8px"}
                size="md"
                value={formData.mensaje}
                onChange={(event) =>
                  setFormData({ ...formData, mensaje: event.target.value })
                }
              />
              <Box textAlign={"center"} pt={8}>
                <Button
                  py={3}
                  px={7}
                  color={"#fff"}
                  background={"#000"}
                  textTransform={"uppercase"}
                  borderRadius={"0"}
                  fontSize={"22px"}
                  lineHeight={"22px"}
                  letterSpacing={"0.02em"}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {lang ? "Send" : "Enviar"}
                </Button>
              </Box>
            </FormControl>
            <VStack
              px={0}
              display={gracias ? "flex" : "none"}
              flexDirection="column"
              textAlign={"center"}
              spacing={5}
              py={{ md: 32, xs: 16 }}
              fontFamily={"Montserrat"}
            >
              <Heading color={"rojo"}>{lang ? "Sent!" : "¡Enviado!"}</Heading>
              <Text
                fontSize={"27px"}
                letterSpacing={"-0.02em"}
                fontWeight={"500"}
                color={"rojo"}
              >
                {lang
                  ? "Thank you for contacting us."
                  : "Gracias por escribirnos."}
              </Text>
              <Text color={"#727272"}>
                {lang
                  ? "We are thrilled to welcome you in Callia wineries. For guided tours reservations we will be contacting you wednesday to friday from 10:00 to 16:00hs."
                  : `Estamos muy contentos de recibirte en Bodegas Callia. Para
                reservas de visitas guiadas nos comunicaremos con vos de
                miércoles a viernes de 10:00 a 16:00hs.`}
              </Text>
              <Box textAlign={"center"} pt={12}>
                <Button
                  py={3}
                  px={7}
                  color={"#fff"}
                  textTransform={"uppercase"}
                  borderRadius={"0"}
                  fontSize={"22px"}
                  lineHeight={"22px"}
                  letterSpacing={"0.02em"}
                  fontWeight={"500"}
                  onClick={() => {
                    setGracias(false);
                    onClose();
                  }}
                  colorScheme={"calliaRed"}
                >
                  {lang ? "UNDERSTOOD" : "ENTENDIDO"}
                </Button>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TourModal;
