export const generarTextosCertificaciones = (lang) => {
  const certificacionesPrimeroList = lang
    ? [
        "BRC (British Retail Consortium): Food Safety, Quality, Legal Compliance and Authenticity Standards.",
        "ISO 9001: Quality Management Systems.",
        "ISO 14001: Environmental Management System.",
        "ISO 45001: Occupational Health and Safety Management System.",
      ]
    : [
        "BRC (British Retail Consortium): Norma de Seguridad Alimentaria, Calidad, Legalidad y Autenticidad.",
        "ISO 9001: Sistema de Gestión de Calidad.",
        "ISO 14001: Sistema de Gestión del Medio Ambiente.",
        "ISO 45001: Sistema de Gestión de Seguridad y Salud en el Trabajo.",
      ];

  const certificacionesPrimerParrafo = lang
    ? "At Salentein Family of Wines we are committed to the efficient management of our operations, emphasizing the safety, quality and authenticity of our products, as well as the prevention of environmental pollution and maintaining occupational safety, in accordance with the following implemented and certified international standards:"
    : `a gestionar eficientemente nuestros procesos, haciendo énfasis en la
    inocuidad, calidad y autenticidad de nuestros productos; en la
    prevención de la contaminación del medio ambiente y del deterioro de
    la salud, de acuerdo con las siguientes normas internacionales,
    implementadas y certificadas:`;

  const certificacionesSegundoParrafo = lang
    ? "Argentine Sustainability Protocol for Wineries: The objective of which is to strengthen the long-term environmental, social and economic sustainability of a company. It is a standard of sustainable practices that addresses the challenges of both the environmental concerns as well as the social and economic aspects of business. The standard focuses on various practices, among which are those that address issues such as soil management, irrigation, efficient use of water and energy, waste management, air quality and human resources."
    : `Protocolo de Sustentabilidad de Bodegas de Argentina: Tiene como
    objetivo fortalecer la sustentabilidad ambiental, social y económica
    de la empresa a largo plazo. Se trata de un estándar de prácticas
    sustentables para abordar los desafíos del cuidado tanto ambiental
    como así también sociales y económicos propios del negocio. Está
    enfocado en distintas prácticas, entre las que se encuentran
    aquéllas que atienden a cuestiones como el manejo del suelo, el
    riego, el uso eficiente del agua y de la energía, la gestión de
    residuos, la calidad del aire y los recursos humanos.`;

  const certificacionesSegundoList = lang
    ? [
        "Improves the competitiveness of Argentine wines and increases their value.",
        "Lays the foundations for the implementation of Sound Sustainable Practices.",
        "Protects the organisation against future threats of trade limitations.",
        "Establishes a high-standards policy of sustainable practices.",
        "Disseminates information about the importance of sustainable practices in vineyards and wineries.",
        "Complements the implementation of measures for the adaptation and mitigation of climate change.",
        "Products suitable for vegans: The production of our wines has been informed by the LIAF CONTROL process that certifies them as suitable for vegans; they are made with raw materials, additives and coadjutants that are not of animal origin or in any way derived from animals.",
        "Organic certification: We are currently in the process of transforming five of the grape vineyards from conventional cultivation to fully organic cultivation.",
      ]
    : [
        "Mejora la competitividad y el valor agregado de los vinos argentinos.",
        "Sienta las bases para la adquisición de Buenas Prácticas Sustentables.",
        "Protege a la organización ante eventuales amenazas de limitación comercial.",
        "Establece una política con altos estándares de prácticas sustentables.",
        "Educa acerca de la importancia de las prácticas sustentables en viñedos y bodegas.",
        "Colabora con la implementación de medidas para la adaptación y mitigación del cambio climático.",
        "Productos aptos para veganos: Nuestros vinos han aprobado el proceso LIAF CONTROL que los certifica como aptos para veganos, elaborados con materias primas, aditivos y coadyuvantes que no provienen de origen animal o derivados de animales.",
        "Certificación orgánica: Hemos comenzado a transformar cinco de las fincas de uvas de cultivo convencional a cultivo orgánico.",
      ];

  return {
    certificacionesPrimeroList,
    certificacionesSegundoList,
    certificacionesPrimerParrafo,
    certificacionesSegundoParrafo,
  };
};
