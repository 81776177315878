import { Box } from "@chakra-ui/react";
import React from "react";

const HeaderBanner = ({ bgImg }) => {
  return (
    <Box
      width="100%"
      background={`url(${bgImg});`}
      backgroundRepeat={"no-repeat"}
      backgroundSize={{ md: "100% 100%", xs: "cover" }}
      bgPos={"center"}
    >
      <Box
        py={40}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img src="./img/logo-callia-color-bronce.png" alt="" />
      </Box>
    </Box>
  );
};

export default HeaderBanner;
