export const generarTextosSustentabilidad = (lang) => {
  const sustentabilidadPrimeroParagraphs = lang
    ? [
        "Our objective at Salentein Family of Wines is clear: to produce the highest quality wines, remaining committed to the land in which they are born, and achieving long-term sustainability in order to satisfy present needs without compromising the capacity of future generations. And, at the same time, ensuring a balance between economic growth, environmental protection and social welfare.",
        "We at Salentein Family of Wines respect nature and, to that end, we work to involve the local community,  to prevent environmental contamination, promote development and collaboration through both internal and external clear communications with customers and suppliers. Through this, those people become an essential component to our objective that is then reflected in the expression of our wines.",
        "As part of this commitment, we at Bodegas Callia consider sustainability as a central element for developing systems of production and managing our vineyards. For this reason, a Self-Assessment Argentine Sustainability Protocol for Wineries has been implemented since 2013 and is recertified every three years. The first certification for Bodega and Finca Callia, located in Pie de Palo, Caucete, was awarded in 2017 and recertified in 2020.",
      ]
    : [
        `elaborar vinos de la más alta calidad, comprometidos con la tierra
    en la que nacen y logrando sostenibilidad a largo plazo con el fin
    de satisfacer las necesidades del presente sin comprometer la
    capacidad de las generaciones futuras. Y, al mismo tiempo,
    garantizar un equilibrio entre el crecimiento económico, el
    cuidado del ambiente y el bienestar social.`,
        `En Salentein Family of Wines, entonces, respetamos la naturaleza
    y, al mismo tiempo, involucramos a la comunidad de la zona,
    previniendo la contaminación del medioambiente, fomentando el
    desarrollo y el trabajo en equipo a través de una comunicación
    transparente con los clientes y proveedores, tanto internos como
    externos. De esta manera, las personas se convierten en un
    componente esencial que se refleja en la expresión de nuestros
    vinos.`,
        `Como parte de este compromiso, en Bodegas Callia también
    consideramos a la sustentabilidad como un elemento central para
    desarrollar los sistemas productivos y manejar los viñedos. Por
    ese motivo, desde 2013, se implementa un Protocolo de
    Autoevaluación de Sustentabilidad de Bodegas de Argentina, que se
    certifica cada tres años. La primera certificación para la Bodega
    y la Finca Callia, ubicada en Pie de Palo, Caucete, se obtuvo en
    2017, recertificando en 2020.`,
      ];
  const sustentabilidadTerceroList = lang
    ? [
        {
          title: "Goal N°3 - Good health and well-being:",
          text: "Good health and well-being: The safety of human talent is important, which is why we look after our staff through health campaigns and monitoring, and our occupational health and safety team ensures integrity and safety in the workplace that allows things to work as they should, helping to achieve the company's objectives.",
        },
        {
          title: "Goal N°4 - Quality education:",
          text: "Quality education: In terms of human resources, we are committed to training our staff to perform their functions correctly.",
        },
        {
          title: "Goal N°6 - Clean water and sanitation:",
          text: "Clean water and sanitation: We are pioneers in the wine sector in the development of baseline hydrogeological information and groundwater monitoring. We invest in research to understand the impact of climate change on the availability of water resources in the Uco and Pedernal Valleys. Likewise, we invest in the installation of drip irrigation systems in all our estates and in the technology that allows us to know precisely the water demands of our vines. Additionally, we develop and employ innovative crop management techniques. At the same time, we treat 100% of our liquid effluents, resulting in water that is suitable for irrigation to be used in our afforestation projects.",
        },
        {
          title: "Goal N°7 - Affordable and clean energy:",
          text: "Affordable and clean energy: We have implemented improvements in the vinification processes, resulting in reduced reliance on chilling and conducting harvests at night. Our production processes make efficient use of our machinery and equipment. With regard to energy for illumination, we have upgraded to low energy-consumption lighting, added motion sensors and timers, and incorporated LED technology.",
        },
        {
          title: "Goal N°8 - Decent work and economic growth:",
          text: "Decent work and economic growth: We have a Code of Conduct with instructions and practical guidelines that we adhere to daily, whether in routine situations or the uncommon.",
        },
        {
          title: "Goal N°12 - Responsible production and consumption:",
          text: "Responsible production and consumption: With regard to our activity in primary production, there is zero tillage and we use drip irrigation. The work concept in our vineyards is that of precision viticulture, which involves varying the work at different places and sectors according to what each requires. In the production stage, the use of lightweight bottles has been implemented for most of our products, reducing the weight of supplies and packaging.",
        },
        {
          title: "Goal N°13 - Climate action:",
          text: "We have a clear objective to reduce our greenhouse gas emissions, helping to mitigate the effects of climate change, for which we rely on calculations of our own corporate carbon footprint in order to establish a baseline.",
        },
      ]
    : [
        {
          title: "Objetivo N°3 - Salud y bienestar:",
          text: `La seguridad del talento humano es importante, por eso realizamos un
      cuidado del personal, a través de campañas y seguimiento sanitario. Al
      mismo tiempo, nuestro equipo de salud y seguridad ocupacional vela por
      la integridad y seguridad en las áreas de trabajo, permitiendo un buen
      desempeño de funciones y llegando a cumplimentar los objetivos de la
      compañía.`,
        },
        {
          title: "Objetivo N°4 - Educación de calidad:",
          text: `Desde el área de recursos humanos, nos comprometemos en la
        capacitación de nuestro personal para desempeñar sus actividades
        correctamente.`,
        },
        {
          title: "Objetivo N°6 - Agua limpia y saneamiento:",
          text: `Somos pioneros en el sector vitivinícola en cuanto al desarrollo de
        información hidrogeológica de base y el monitoreo de aguas
        subterráneas. Invertimos en investigación para entender el impacto del
        cambio climático en la disponibilidad del recurso hídrico en el Valle
        de Uco y Pedernal. Asimismo, invertimos en la instalación de sistemas
        de riego por goteo en todas nuestras fincas y en tecnología que nos
        permite conocer con precisión la demanda de agua de nuestras plantas.
        Además, desarrollamos y aplicamos técnicas de manejo de cultivos
        innovadoras. Al mismo tiempo, tratamos el 100% de nuestros efluentes
        líquidos, obteniendo agua apta para riego que utilizamos en nuestros
        proyectos de forestación.`,
        },
        {
          title: "Objetivo N°7 - Energía asequible y no contaminante:",
          text: `Hemos implementado mejoras en los procesos de vinificación, que
        implican menor uso de frío, realizando cosechas nocturnas. Realizamos
        procesos con un uso eficiente de sus máquinas y equipos. En relación
        con la energía lumínica, realizamos un recambio de luminarias a bajo
        consumo, agregamos colocación de sensores de movimiento y
        temporizadores e incorporamos tecnología Led.`,
        },
        {
          title: "Objetivo N°8 - Trabajo decente y crecimiento económico:",
          text: `Contamos con un Código de Conducta con instrucciones y lineamientos
        prácticos para aplicar en nuestro trabajo diario, ya sea en
        situaciones rutinarias o fuera de lo común.`,
        },
        {
          title: "Objetivo N°12 - Producción y consumo responsable:",
          text: `Entre las acciones que realizamos en la producción primaria, está la
        labranza cero y el uso de riego por goteo. El concepto de trabajo en
        nuestros viñedos es el de viticultura de precisión, que implica
        diferenciar los trabajos en distintos sitios y sectores, de acuerdo a
        lo que cada uno de ellos requiere. En la etapa productiva, se ha
        implementado el uso de botellas livianas en la mayoría de nuestros
        productos, disminuyendo el peso de insumos y embalajes.`,
        },
        {
          title: "Objetivo N°13 - Acción por el clima:",
          text: `Contamos con el objetivo claro de reducir nuestras emisiones de gases
        de efecto invernadero, contribuyendo a mitigar los efectos del cambio
        climático. A su vez, realizamos nuestra medición de la Huella de
        carbono corporativa.`,
        },
      ];

  const sustentabilidadTerceroParagraphs = lang
    ? {
        top: `At the 2015 World Summit on Sustainable Development in New York, the document "Transforming our world: the 2030 Agenda for Sustainable Development" was approved. There, 17 Sustainable Development Goals (SDGs) and 169 targets were approved that take into account all dimensions of sustainable development: economic, environmental and social. This is the agenda Salentein Family of Wines has adopted to guide us, and the following are the goals with which our business activities and production processes are particularly involved:`,
        bottom: `At Salentein Family of Wines, we don’t view sustainability solely as a list of practices that contribute to the reduction of negative environmental impacts, instead we integrate the concept into a synergy of actions that allow us to strengthen and grow both our company and our employees. This is how we involve the environment, economics and society in the quality of the wines we make, allowing Salentein to project as a sustainable space and be an example for the region.`,
      }
    : {
        top: `En la Cumbre Mundial sobre Desarrollo Sostenible, realizada en Nueva
        York en 2015, se aprobó el documento “Transformar nuestro mundo: la
        Agenda 2030 para el Desarrollo Sostenible”. Allí se aprobaron 17
        Objetivos de Desarrollo Sostenible (ODS) y 169 metas que tienen en
        cuenta todas las dimensiones del desarrollo sostenible: la económica, la
        ambiental y la social. En Salentein Family of Wines los tenemos en
        cuenta y estos son los objetivos con los que particularmente están
        involucradas nuestras actividades y procesos productivos:`,
        bottom: `En Salentein Family of Wines no concebimos a la sustentabilidad como una
        enumeración de prácticas que contribuyen a la reducción de impactos
        naturales, sino que la integramos en una sinergia de acciones que
        permiten fortalecer y hacer crecer tanto a nuestra compañía como a
        nuestros empleados. De esta manera, ambiente, economía y sociedad están
        involucrados en la calidad de los vinos que elaboramos y permiten
        proyectar a Salentein como espacio sustentable y ejemplo de la región.`,
      };

  const sustentabilidadSegundoList = lang
    ? [
        {
          title: "Products suitable for vegans:",
          text: "The production of our wines has been informed by the LIAF CONTROL process that certifies them as suitable for vegans; they are made with raw materials, additives and coadjutants that are not of animal origin or in any way derived from animals.",
        },
        {
          title: "Organic certification:",
          text: "We are currently in the process of transforming five of the grape vineyards from conventional cultivation to fully organic cultivation.",
        },
        {
          title: "Corporate carbon footprint:",
          text: "We have undertaken the calculation of our corporate carbon footprint to call to attention the opportunities for improvement not only at a communicative or social level, but also economically and with regard to efficiency.",
        },
        {
          title: "Water-use footprint:",
          text: "This is an indicator of the freshwater used, including not only the direct use of water, but also the indirect use. It is a geographical and temporally explicit indicator that registers volumes of water consumption and subsequent contamination, in addition to geographical location and time of use. This method allows us to calculate the Direct and Indirect Water Footprint of the Wineries and Estates.",
        },
        {
          title: "Waste management:",
          text: "We have a recycling program. Cardboard, plastic, glass and metal waste originating through the production process is identified, separated and removed by authorized companies in order to ensure that the subsequent by-products are 100% recycled. This is one way we recognise the importance of managing waste in a sustainable, safe and responsible manner.",
        },
      ]
    : [
        {
          title: "Productos aptos para veganos:",
          text: `Nuestros vinos han aprobado el proceso LIAF CONTROL que los
        certifica como aptos para veganos, elaborados con materias
        primas, aditivos y coadyuvantes que no provienen de origen
        animal o derivados de animales.`,
        },
        {
          title: "Certificación orgánica:",
          text: `Hemos comenzado a transformar cinco de las fincas de uvas de
        cultivo convencional a cultivo orgánico.`,
        },
        {
          title: "Huella de carbono corporativa:",
          text: `Hemos decidido realizar el cálculo de la Huella de carbono
        corporativa para poner de manifiesto posibilidades de mejora no
        solo a nivel comunicativo o social, sino también económico y de
        eficiencia.`,
        },
        {
          title: "Huella de agua: ",
          text: `Se trata de un indicador del agua dulce utilizada, que contempla
        no sólo el uso directo de agua, sino también el indirecto. Es un
        indicador geográfico y temporalmente explícito que muestra los
        volúmenes de consumo de agua y la contaminación, así como la
        localización geográfica y el momento de uso. Por lo tanto,
        realizamos el cálculo de Huella de Agua Directa e Indirecta de
        las Bodegas y Fincas.`,
        },
        {
          title: "Manejo de residuos: ",
          text: `Contamos con un programa de reciclado. Los residuos de cartón,
        plástico, vidrio y metales originados durante el proceso de
        producción son identificados, separados y retirados por empresas
        habilitadas para garantizar su posterior reciclado en un 100%.
        De esta manera, se reconoce la importancia de gestionar los
        residuos de forma sostenible, segura y responsable.`,
        },
      ];

  return {
    sustentabilidadSegundoList,
    sustentabilidadTerceroList,
    sustentabilidadPrimeroParagraphs,
    sustentabilidadTerceroParagraphs,
  };
};
