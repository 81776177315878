import { Container, GridItem, Text } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import {
  ChakraBox,
  opacityStaggerVariants,
  opacityVariants,
} from "../utils/animations";
import { getListadoVinos } from "../utils/APICalls";
import UnVino from "./UnVino";
import { LanguajeContext } from "../App";
function compareFn(a, b) {
  if (parseInt(a.nroOrden) > parseInt(b.nroOrden)) {
    return 1;
  }

  if (parseInt(a.nroOrden) < parseInt(b.nroOrden)) {
    return -1;
  }
  return 0;
}

const apiImages = "https://bodegascallia.com/api/";
const FilaVino = ({ vino, active, text }) => {
  const [listadoVinos, setListadoVinos] = useState();
  const { lang } = useContext(LanguajeContext);
  useEffect(() => {
    getListadoVinos(vino.id, lang).then((json) => {
      let vinosArray = json.extraInfo;
      vinosArray.sort(compareFn);
      setListadoVinos(vinosArray);
    });
  }, [vino.id, lang]);

  const VinoRowGenerator = (unVino) => {
    if (unVino.esVino === "1") {
      return (
        <GridItem
          colSpan={{ lg: unVino.cantidadColumnas, xs: "12" }}
          key={unVino.id}
        >
          <ChakraBox variants={opacityVariants}>
            <UnVino
              nombre={unVino.nombre}
              id={unVino.id}
              imagen={apiImages + unVino.pathFoto}
            />
          </ChakraBox>
        </GridItem>
      );
    } else {
      return (
        <GridItem colSpan={unVino.cantidadColumnas} key={unVino.id}>
          <img src={apiImages + unVino.pathFoto} alt="" />
        </GridItem>
      );
    }
  };

  if (vino.nroOrden === active) {
    return (
      <>
        <AnimatePresence>
          <ChakraBox
            variants={opacityVariants}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            {listadoVinos && (
              <Text
                fontFamily={"Montserrat"}
                textAlign={"center"}
                maxW={"950px"}
                mx={"auto"}
                py={9}
                fontSize={"18px"}
                lineHeight={"27px"}
                color={"#000"}
                letterSpacing={"0.02em"}
              >
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
              </Text>
            )}
          </ChakraBox>
        </AnimatePresence>
        <Container maxW={"container.xl"}>
          {listadoVinos && (
            <ChakraBox
              variants={opacityStaggerVariants}
              initial="hidden"
              animate="show"
              exit="exit"
              className={"vinos-grid"}
            >
              {listadoVinos.map((unVino) => VinoRowGenerator(unVino))}
            </ChakraBox>
          )}
        </Container>
      </>
    );
  }
  return null;
};

export default FilaVino;
