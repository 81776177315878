import { Text } from "@chakra-ui/react";
const BotonVino = ({ setActiveVino, vino, active, idVino }) => {
  const tabHandler = () => {
    setActiveVino(idVino);
    window.history.replaceState(null, "", "/vinos/" + vino.toLowerCase());
  };

  return (
    <Text
      fontSize={"lg"}
      fontWeight={active === idVino ? "600" : "400"}
      fontStyle={"italic"}
      cursor={"pointer"}
      _hover={{
        fontWeight: "600",
      }}
      onClick={tabHandler}
      borderBottom={""}
    >
      {vino}
    </Text>
  );
};

export default BotonVino;
