import { Box, Container, HStack, Image, Text, Divider } from "@chakra-ui/react";
import { useContext } from "react";
import { LanguajeContext } from "../App";
const Footer = () => {
  const { lang } = useContext(LanguajeContext);
  return (
    <Container maxW={"full"} backgroundColor="#FFFBF8" mx={"auto"}>
      <Box
        py={16}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Image src="/img/logo-callia-color.svg" maxW={"150px"} />
        <HStack alignItems={"center"} pt={12}>
          <Divider w="60px" borderColor={"#D2782A"} />
          <Text
            textTransform={"uppercase"}
            color={"#D2782A"}
            px={5}
            letterSpacing={"0.11em"}
          >
            {lang ? "Contact" : "Contacto"}
          </Text>
          <Divider w="60px" borderColor={"#D2782A"} />
        </HStack>
        <HStack pt={5} spacing={6}>
          <a href="https://www.instagram.com/bodegascallia/">
            <Image src="/img/ig-yellow.svg" />
          </a>
          <a href="https://www.facebook.com/BodegasCallia">
            <Image src="/img/fb-yellow.svg" />
          </a>
        </HStack>
      </Box>
    </Container>
  );
};

export default Footer;
