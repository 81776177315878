import { createIcon } from "@chakra-ui/react";

export const ChevronDownIcon = createIcon({
  displayName: "ChevronDownIcon",
  viewBox: "0 0 9 5",
  path: <path d="M4.375 5L8.75 0H0L4.375 5Z" fill="#F8EFE7" />,
});

export const ChevronDownIconRed = createIcon({
  displayName: "ChevronDownIcon",
  viewBox: "0 0 9 5",
  path: <path d="M4.375 5L8.75 0H0L4.375 5Z" fill="#931414" />,
});
