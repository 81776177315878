import {
  Container,
  Box,
  Text,
  Heading,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import { AnimatePresence } from "framer-motion";
import { ChakraBox, swipeToLeftVariants } from "../utils/animations";

const ValleZonda = ({ isVisible, title, texto }) => {
  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <ChakraBox
          variants={swipeToLeftVariants}
          initial="hidden"
          animate="show"
          exit="exit"
          pos={"absolute"}
          right={"0"}
          left={"0"}
          top={"0"}
        >
          <Container maxW={"container.xl"} position={"relative"} mb={20}>
            <Box background={"crema"} boxShadow={"10px 10px 15px #999"}>
              <Box position={"relative"} p={10}>
                <Heading
                  top={{ md: "-50%", xs: "-70%" }}
                  mx={"auto"}
                  left={"0"}
                  right={"0"}
                  fontSize={{ md: "6xl", xs: "5xl" }}
                  lineHeight={{ md: "82.65px", xs: "50px" }}
                  textAlign={"center"}
                  color="rojo"
                  fontStyle={"italic"}
                  fontFamily={"'Playfair Display', serif"}
                  position={"absolute"}
                >
                  {title}
                </Heading>
              </Box>
              <Grid
                templateColumns={{ xl: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}
                pb={16}
              >
                <GridItem
                  display={{ xl: "initial", xs: "flex" }}
                  justifyContent={"center"}
                  pb={{ xl: 0, xs: 8 }}
                >
                  <Image
                    src="./img/zonda.jpg"
                    position={{ xl: "absolute", xs: "initial" }}
                    left={"0"}
                  />
                </GridItem>
                <GridItem>
                  <Text
                    maxW={"90%"}
                    fontFamily={"'Montserrat', sans-serif"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    color={"#000"}
                    px={{ xl: 16, xs: 0 }}
                    mx={{ xl: 0, xs: "auto" }}
                    pt={12}
                    textAlign={{ xl: "left", xs: "center" }}
                  >
                    {texto.map((unTexto) => (
                      <Text pb={4}>{unTexto}</Text>
                    ))}
                  </Text>
                </GridItem>
              </Grid>
            </Box>
          </Container>
        </ChakraBox>
      )}
    </AnimatePresence>
  );
};

export default ValleZonda;
