import { Container } from "@chakra-ui/react";
import CertificacionesPrimero from "../components/CertificacionesPrimero";
import CertificacionesSegundo from "../components/CertificacionesSegundo";
import HeaderBannerSmall from "../components/HeaderBannerSmall";
import { motion } from "framer-motion";
import { opacityRouteVariants } from "../utils/animations";
import { useContext } from "react";
import { LanguajeContext } from "../App";
import { generarTextosCertificaciones } from "../utils/textosCertificaciones";

const Certificaciones = () => {
  const { lang } = useContext(LanguajeContext);
  const {
    certificacionesPrimeroList,
    certificacionesSegundoList,
    certificacionesPrimerParrafo,
    certificacionesSegundoParrafo,
  } = generarTextosCertificaciones(lang);
  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <HeaderBannerSmall
        image={"./img/certificaciones-header.webp"}
        title={lang ? "Certifications" : "Certificaciones"}
      />
      <Container maxW={"container.xl"} px={0}>
        <CertificacionesPrimero
          title={lang ? "At" : "En"}
          subtitle={lang ? "we are commited..." : "nos comprometemos..."}
          firstParagraph={certificacionesPrimerParrafo}
          listItems={certificacionesPrimeroList}
          secondParagraph={certificacionesSegundoParrafo}
          bottomText={
            lang
              ? "The sites in San Juan that have received certification are:"
              : "Los sitios certificados obtenidos en San Juan son:"
          }
          sitios={
            lang
              ? ["Bodegas Callia winery", "Fincas Callia estates"]
              : ["Bodegas Callia", "Fincas Callia"]
          }
        />
        <CertificacionesSegundo
          title={
            lang
              ? "Benefits of the Argentine Sustainability Protocol for Wineries:"
              : "Aportes del Protocolo de Sustentabilidad de Bodegas de Argentina:"
          }
          listItems={certificacionesSegundoList}
        />
      </Container>
    </motion.div>
  );
};

export default Certificaciones;
