import React from "react";
import {
  Grid,
  GridItem,
  Image,
  Box,
  HStack,
  Text,
  Heading,
  Divider,
  VStack,
  Button,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { ArrowRightRedIcon } from "./icons/ArrowRight";
import { ReservaIcon } from "./icons/ReservaIcon";
import TourModal from "./TourModal";
import { motion } from "framer-motion";
import { opacityVariants } from "../utils/animations";

import { useContext } from "react";
import { LanguajeContext } from "../App";

const CuadroImagen = ({
  headerText,
  headerNumber,
  title1,
  title2,
  texto,
  variant,
  horarios,
  duracion,
  reserva,
  modalType,
  imagen,
  href,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { lang } = useContext(LanguajeContext);

  if (variant === "noButton") {
    return (
      <Grid templateColumns={{ lg: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}>
        <GridItem bg={"crema"}>
          <Box
            className="center"
            px={{ xl: 28, lg: 20, xs: 8 }}
            py={{ xl: 0, lg: 8, xs: 20 }}
          >
            <motion.div
              variants={opacityVariants}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
            >
              <VStack alignItems={"flex-start"}>
                <Heading
                  size={"2xl"}
                  color={"rojo"}
                  fontFamily={"'Playfair Display', serif"}
                >
                  {title1}
                  <Text
                    fontStyle={"italic"}
                    fontFamily={"'Playfair Display', serif"}
                  >
                    {title2}
                  </Text>
                </Heading>
                <Box py={5}>
                  <Text
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    mt="0"
                    color={"#393835"}
                    fontFamily={"'Montserrat', sans-serif"}
                    letterSpacing="0.01em"
                  >
                    {texto}
                  </Text>
                </Box>
              </VStack>
            </motion.div>
          </Box>
        </GridItem>
        <GridItem>
          <Image
            src={imagen}
            objectFit={"cover"}
            w="100%"
            boxSize={"100%"}
          ></Image>
        </GridItem>
      </Grid>
    );
  }

  if (variant === "tour") {
    return (
      <Grid templateColumns={{ lg: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}>
        <GridItem>
          <Box
            className="center"
            px={{ xl: 28, lg: 20, xs: 8 }}
            py={{ xl: 0, lg: 8, xs: 20 }}
            background={"crema"}
          >
            <motion.div
              variants={opacityVariants}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
            >
              <VStack alignItems={"flex-start"}>
                <Heading
                  fontSize={"35px"}
                  fontWeight={"700"}
                  lineHeight={"24px"}
                  fontFamily={"'Playfair Display', serif"}
                  color={"rojo"}
                  fontStyle={"italic"}
                  pb={5}
                >
                  {title1}
                </Heading>
                <Text
                  fontSize={"14px"}
                  lineHeight={"22px"}
                  fontWeight={"400"}
                  color={"calliaGray"}
                  fontFamily={"'Montserrat', sans-serif"}
                  pb={5}
                >
                  {texto}
                </Text>

                <Box>
                  {horarios ? (
                    <Text
                      fontSize={{ md: "12px", lg: "12px" }}
                      lineHeight={"22px"}
                      fontStyle={"italic"}
                      fontWeight={"bold"}
                    >
                      {lang
                        ? "Available times and days:"
                        : "Días y Horarios disponibles:"}
                    </Text>
                  ) : (
                    ""
                  )}
                  {horarios
                    ? horarios.map((horario) => (
                        <Text
                          fontSize={{ md: "12px", lg: "12px" }}
                          lineHeight={"22px"}
                          fontStyle={"italic"}
                        >
                          {horario}
                        </Text>
                      ))
                    : ""}
                </Box>
                <Text
                  fontSize={"12px"}
                  lineHeight={"22px"}
                  fontStyle={"italic"}
                  fontFamily={"'Montserrat', sans-serif"}
                  color={"calliaGray"}
                >
                  {duracion && (
                    <strong>
                      {lang ? "Duration:" : "Duracion:"}{" "}
                      <span className="fw400">{duracion} </span>
                    </strong>
                  )}
                </Text>
                <Text
                  fontSize={{ md: "12px", lg: "12px", xs: "12px" }}
                  lineHeight={"24px"}
                  fontFamily={"Montserrat"}
                >
                  {reserva}
                </Text>
                <Box>
                  <Text
                    fontSize={"12px"}
                    lineHeight={"22px"}
                    fontStyle={"italic"}
                    fontWeight={"bold"}
                    fontFamily={"'Montserrat', sans-serif"}
                    color={"calliaGray"}
                  >
                    {lang
                      ? "Inquiries about cost, days and hours"
                      : "Consultas sobre costo, días y horarios:"}
                  </Text>
                  <Text
                    fontSize={{ md: "12px", lg: "12px", xs: "12px" }}
                    lineHeight={"24px"}
                    fontStyle={"italic"}
                    fontFamily="Montserrat"
                    pb={5}
                  >
                    {lang
                      ? "WhatsApp 2645064021 or info.bodegascallia@salentein.com"
                      : "WhatsApp 2645064021 o info.bodegascallia@salentein.com"}
                  </Text>
                </Box>
                <Button
                  colorScheme={"calliaRed"}
                  variant="solid"
                  borderRadius={"0px"}
                  fontSize={"14px"}
                  leftIcon={<ReservaIcon />}
                  onClick={onOpen}
                  _hover={{ backgroundColor: "#580C0C" }}
                >
                  {lang ? "BOOK TOUR" : "RESERVAR TOUR"}
                </Button>
              </VStack>
            </motion.div>
          </Box>
        </GridItem>
        <GridItem>
          <Image
            src={imagen}
            objectFit={"cover"}
            w="100%"
            boxSize={"100%"}
          ></Image>
        </GridItem>
        <TourModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          type={modalType}
        />
      </Grid>
    );
  }

  return (
    <Grid templateColumns={{ xs: "repeat(1,1fr)", lg: "repeat(2, 1fr)" }}>
      <GridItem bgColor={"crema"}>
        <motion.div
          variants={opacityVariants}
          initial="hidden"
          whileInView="show"
        >
          <Box position={"relative"} p={{ xs: 12, "2xl": 32 }}>
            <VStack alignItems={"flex-start"}>
              <HStack
                justifyContent="space-between"
                w={"100%"}
                color={"#393835"}
              >
                <Text
                  fontSize={"md"}
                  color={"calliaGray"}
                  textTransform={"uppercase"}
                  fontFamily={"Montserrat, sans-serif"}
                >
                  {headerText}
                </Text>
                <Divider
                  orientation={"horizontal"}
                  borderColor={"#000"}
                  w={"60%"}
                />
                <Text
                  fontSize={"60px"}
                  fontWeight={"600"}
                  fontFamily={"'Playfair Display', serif"}
                  m={0}
                >
                  {headerNumber}.
                </Text>
              </HStack>
              <Heading
                size={"2xl"}
                color={"calliaGray"}
                fontFamily={"'Playfair Display', serif"}
              >
                {title1}
                <span style={{ fontStyle: "italic" }}>{title2}</span>
              </Heading>
              <Box py={{ xs: 5, "2xl": 12 }}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  mt="0"
                  color={"#393835"}
                  fontFamily={"'Montserrat', sans-serif"}
                >
                  {texto}
                </Text>
              </Box>
            </VStack>
            <Link
              href={href}
              textDecoration={"none"}
              _hover={{ textDecoration: "none" }}
            >
              <Button
                height={"35px"}
                letterSpacing={"0.07em"}
                colorScheme="calliaRed"
                variant="outline"
                borderRadius={0}
                rightIcon={<ArrowRightRedIcon />}
                mt={10}
                textTransform={"uppercase"}
                fontSize={"sm"}
                _hover={{
                  backgroundColor: "#ecd5c0 ",
                  color: "rojo",
                  border: "1px solid transparent",
                }}
                href={href}
              >
                {lang ? "See more" : "Ver mas"}
              </Button>
            </Link>
          </Box>
        </motion.div>
      </GridItem>
      <GridItem>
        <Image
          src={imagen}
          objectFit={"cover"}
          w="100%"
          boxSize={"100%"}
        ></Image>
      </GridItem>
    </Grid>
  );
};

export default CuadroImagen;
