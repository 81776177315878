import {
  Grid,
  GridItem,
  Image,
  Text,
  Box,
  VStack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { CheckIcon } from "./icons/Check";
const CertificacionesSegundo = ({ title, listItems }) => {
  return (
    <Box pt={24}>
      <Grid templateColumns={{ lg: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}>
        <GridItem>
          <Box px={{ md: 24, xs: 8 }} py={16}>
            <Text
              fontFamily={"Montserrat"}
              fontSize={"18px"}
              fontWeight={"600"}
              lineHeight={"20px"}
              color={"rojo"}
              pb={9}
            >
              {title}
            </Text>
            <VStack
              fontSize={"13px"}
              fontFamily={"Montserrat"}
              lineHeight={"21px"}
              color={"calliaGray"}
              alignItems={"flex-start"}
              spacing={4}
            >
              {listItems.map((unItem) => (
                <HStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                  <Icon as={CheckIcon} mt={1} />
                  <Text>{unItem}</Text>
                </HStack>
              ))}
            </VStack>
          </Box>
        </GridItem>
        <GridItem pos={"relative"}>
          <Box height={{ lg: "initial", md: "600px", xs: "250px" }}>
            <Image
              src="./img/certificaciones-fondo-2.jpg"
              position={"absolute"}
              right={"0"}
              top={{ xl: "5%", lg: "15%" }}
              display={{ md: "initial", xs: "none" }}
            />
            <Image
              src="./img/certificaciones-img-2.webp"
              position={"absolute"}
              left={{ xl: "0%", lg: "-10%" }}
              top={{ xl: "17%", lg: "27%", md: "15%" }}
            />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default CertificacionesSegundo;
