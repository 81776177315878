import {
  Grid,
  GridItem,
  Box,
  HStack,
  Heading,
  Divider,
  Text,
  Image,
  Button,
} from "@chakra-ui/react";
import ContactoForm from "../components/ContactoForm";
import {
  ChakraBox,
  comeFromTopVariants,
  opacityRouteVariants,
} from "../utils/animations";
import { motion } from "framer-motion";
import { useContext } from "react";
import { LanguajeContext } from "../App";
const Contacto = () => {
  const { lang } = useContext(LanguajeContext);
  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Box pt={32}>
        <Grid templateColumns={{ md: "repeat(2, 1fr)", xs: "repeat(1, 1fr)" }}>
          <GridItem
            background={"url(./img/contacto.jpg)"}
            position={"relative"}
            backgroundSize={"cover"}
          >
            <Box
              pl={{ lg: 24, md: 16, xs: 8 }}
              pr={{ lg: 20, md: 20, xs: 4 }}
              pt={24}
              pb={16}
            >
              <HStack color={"#393835"} spacing={9} pb={5}>
                <Heading fontFamily={"'Playfair Display'"}>
                  {lang ? "Contact" : "Contacto"}
                </Heading>
                <Divider borderColor={"#393835"} />
              </HStack>
              <Text
                color={"#393835"}
                fontFamily={"'Montserrat', sans-serif"}
                fontWeight={"300"}
                fontSize={"14px"}
                lineHeight={"22px"}
              >
                {lang
                  ? "Do you need help? Get in contact with us, we'll respond to your inquiery as soon as possible."
                  : `¿Necesitás ayuda? Ponete en contacto con nosotros, responderemos
                tu consulta a la brevedad.`}
              </Text>
            </Box>
            <ChakraBox
              variants={comeFromTopVariants}
              initial="hidden"
              animate="show"
            >
              <Box
                background={"#fff"}
                pr={{ lg: 44, md: 32 }}
                pl={{ md: 10, xs: 8 }}
                py={8}
                mr={{ md: "-5%", xs: 0 }}
                ml={{ lg: 24, md: 16 }}
                mb={{ md: 24 }}
              >
                <HStack pb={2}>
                  <Image src="./img/bodegas-callia.svg" />
                </HStack>
                <Text
                  fontSize={"14px"}
                  fontWeight={"300"}
                  lineHeight={"22px"}
                  color={"calliaGray"}
                  pt={4}
                >
                  José María de los Ríos s/n - Pozo de Algarrobos Caucete
                  <br />
                  San Juan - Argentina
                  <br />
                  {lang
                    ? "Phone: +54 (264) 496 0000"
                    : "Teléfono: +54 (264) 496 0000"}
                  <br />
                  {lang
                    ? "Contact: info.bodegascallia@salentein.com"
                    : "Contacto: info.bodegascallia@salentein.com"}
                </Text>
                <a
                  href="https://www.google.com/maps/place/Bodegas+Callia./@-31.6688748,-68.218433,191m/data=!3m1!1e3!4m5!3m4!1s0x96810e518c8e4d21:0xd611f0e480a70064!8m2!3d-31.668675!4d-68.217907"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Button
                    borderRadius={"0"}
                    colorScheme={"calliaRed"}
                    textTransform={"uppercase"}
                    letterSpacing={"0.03em"}
                    fontFamily={"'Montserrat', sans-serif"}
                    fontWeight={"500"}
                    mt={6}
                  >
                    {lang ? "See map" : "ver mapa"}
                  </Button>
                </a>
              </Box>
            </ChakraBox>
          </GridItem>
          <GridItem background={"crema"}>
            <Box
              px={{ lg: 32, md: 12, xs: 12 }}
              py={{ md: 0, xs: 12 }}
              className={"center"}
            >
              <ContactoForm />
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </motion.div>
  );
};

export default Contacto;
